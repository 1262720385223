.cookies-information {
    background-color: $color-text;
    font-size: rem(15px);
    color: white;
    padding: 15px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 99;

    @include min(900px) {
        padding: 20px 0;
        text-align: left;
    }

    a {
        color: $color-brand;
    }

    .btn {
        font-size: 15px;
        width: 150px;
        margin: 5px 15px 0;
        border: none;
        min-height: 37px;

        @include min(900px) {
            float: right;
            margin: -8px 0 -8px 15px;
        }
    }
}