.container {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
    width: 100%;
    padding: 0 5%;
    position: relative;

    @include min(600px) {
        padding: 0 22px;
    }
}

ul {
    &.fish-indent {
        @include min($max-width) {
            font-size: rem(18px);
        }

        li {
            margin-bottom: 1.2rem;
            position: relative;
            padding-left: 2.2rem;

            &::before {
                content: "";
                position: absolute;
                left:0;
                background: url("#{$images-path}/fish.svg") no-repeat center center;
                background-size: contain;
                height: 1.5rem;
                width: 1.5rem;
            }
        }
    }
}