.contact-us {
    padding: 4rem 0 3rem;

    @include min(650px) {
        padding-top: 8rem;
    }

    &__content {
        max-width: 650px;
        margin: 0 auto;
        text-align: center;
    }

    &__title {
        font-size: rem(32px);
        margin-bottom: 1.5rem;
    }

    &__subtitle {
        margin: 0 0 3rem 0;
    }

    &__inputs {
        @include min(650px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0 1rem;
        }
    }

    &__conditions {
        margin-bottom: 2rem;

        & + .alert {
            margin-top: -1.5rem;
        }
    }
}