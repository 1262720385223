form {
    .help-block {
        font-size: rem(14px);
        margin-bottom: 0.75em;
        display: block;
        text-align: left;

        &:first-of-type {
            margin-top: -0.5em;
        }

        [class|="ico"] {
            margin-right: 0.5em;
        }

        &.text-danger {
            color: #dd4b39;
        }
    }
}