.header {
    background-color: #C9E5E8;
    background-image: url('#{$images-path}/foreground.svg'),
                      url('#{$images-path}/clouds.svg'),
                      url('#{$images-path}/clouds.svg'),
                      linear-gradient(0deg, #c9e5e8 0%, #FAFDFD 100%);
    background-repeat: repeat-x, no-repeat, no-repeat, repeat;
    background-position: 50% 100%, left -100px bottom 10vh, right 0 bottom 15vh, 100% 100%;
    background-size: auto;

    @include min(800px) {
        background-size: 55% auto, 50% auto, 50% auto, auto;
        min-height: 80vh;
    }

    @include min(1920px) {
        background-size: 1000px auto, 50% auto, 50% auto, auto;
    }

    &__logo {
        width: 150px;
        margin-top: 30px;
        margin-bottom: 35px;

        @include min($screen-large) {
            width: 180px;
        }

        @include min(800px) {
            margin-bottom: 8vh;
            margin-top: 7vh;
            width: 250px;
            margin-left: 5%;

        }

        @include min($max-width) {
            margin-left: 10%;
        }
    }

    &__content {
        padding-bottom: 100px;

        @include min(800px) {
            padding-bottom: 140px;
            padding-left: 5%;
        }

        @include min($max-width) {
            padding-left: 10%;
        }
    }

    &__title {
        font-size: rem(30px);
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 0.5rem;

        @include min($screen-large) {
            font-size: rem(35px);
        }

        @include min(800px) {
            font-size: rem(42px);
        }
    }

    &__motto {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 6vh;

        li {
            position: relative;
            font-weight: 700;
            font-size: rem(14px);

            @include min($screen-large) {
                font-size: rem(15px);
            }

            @include min(800px) {
                font-size: rem(18px);
            }

            &:not(:first-child)::before {
                content: "";
                display: inline-block;
                width: 4px;
                height: 4px;
                vertical-align: middle;
                background-color: black;
                border-radius: 50%;
                margin: 0 7px;
            }
        }
    }

    &__benefits {
        margin-left: 5%;
        //max-width: 50%;
        margin-bottom: 6vh;

        @include min(500px) {
            max-width: 50%;
        }

        @include min(800px) {
            max-width: 45%;
        }
    }

    &__buttons {
        margin-left: calc(5% + 2.2rem);
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        &__interest {
            margin-bottom: 0.5rem;
        }

        &__more {
            position: relative;
            line-height: 2em;

            &::before {
                @extend %ico;
                content: ico-char(arrow-down);
                font-size: 0.8em;
                padding-right: 8px;
            }

            &:hover {
                &::before {
                    animation: arrowBounce 0.3s ease-in-out infinite alternate;
                }
            }
        }

        @keyframes arrowBounce {
            from {
                transform: translateY(0);
            }
            to {
                transform: translateY(3px);
            }
        }
    }

    &__hand {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 125px;
        max-height: 650px;

        @include min($screen-large) {
            max-width: 40%;
        }

        @include min($max-width) {
            right: 2%;
            max-height: none;
        }
    }
}
