.benefits {
    padding: 2rem 0 1rem;

    @include min($screen-large) {
        padding: 2rem 0 0.5rem;
    }

    &__list {
        @include min($screen-large) {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
    }

    &__item {
        text-align: center;
        margin-bottom: 1rem;
        flex-basis: 33%;
        display: flex;
        align-items: center;
        padding: 0 10px;

        @include min($screen-large) {
            margin-bottom: 2rem;
            flex-direction: column;
        }

        @include min(800px) {
            flex-basis: 20%;
        }

        //@include min(1000px) {
        //  font-size: rem(18px);
        //}

        img {
            height: 2.5em;
            font-size: 1em;
            margin-right: 2rem;

            @include min($screen-large) {
                margin-right: 0;
                height: 3em;
            }

            @include min(800px) {
                height: 3.5em;
            }
        }

        &__text {
            @include min($screen-large) {
                margin-top: 0.5rem;
                width: 135px;
            }

            @include min(800px) {
                margin-top: 1rem;
            }

            @include min(1000px) {
                width: 160px;
            }
        }
    }
}
