/*==============================
	IMAGES PATH
==============================*/

$images-path: "../images";

/*==============================
	FONTS
==============================*/

$font-system: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
$font-monospace: SFMono-Regular, "Roboto Mono", Consolas, "Liberation Mono", Menlo, Courier, monospace;
$font-body: "Open Sans", $font-system;
$font-headings: $font-body;

/*==============================
	COLORS
==============================*/

$color-brand: #eb8025;
$color-background: #ffffff;
$color-text: #424243;

$color-button: $color-brand;
$color-label: #9f9f9f;

$color-light-blue: #C9E5E8;
$color-light-gray: #d4d4d4;
$color-red: #e61313;
$color-green: #91c84e;

$color-neutral-100: #f8f8f8;
$color-neutral-200: #f2f2f2;
$color-neutral-300: #e2e2e2;
$color-neutral-400: #cacaca;
$color-neutral-500: #b2b2b2;
$color-neutral-600: #969696;
$color-neutral-700: #7c7c7c;
$color-neutral-800: #545454;
$color-neutral-900: #161616;

/*==============================
	SIZES
==============================*/

$max-width: 1190px !global;
$screen-large: 450px;
