.extra-tips {
    background-color: $color-light-blue;
    padding: 3rem 0;

    &__list-tips {
        padding-top: 0.5rem;

        @include min(800px) {
            width: 49%;
        }
    }

    &__photo {
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
        width:500px;
        max-width: 80%;
        display: block;
        margin: 2rem auto 0;

        @include min(800px) {
            max-width: 47%;
            position: absolute;
            right: 0;
            bottom: -6.5rem;
            width: auto;
        }
    }
}