// checkboxes
.checkbox {
    position: relative;
    margin-bottom: 0.5rem;

    input[type="checkbox"] {
        position: absolute;
        margin: 3px 0 0 3px;
        left: 0;
        opacity: 0;

        & + label {
            margin-bottom: 0.5rem;
            padding:0;
            font-size: 13px;
            text-transform: none;
            font-weight: 400;
            line-height: normal;
            //display: table-cell;
            //vertical-align: middle;
            //height: 26px;
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            color: $color-label;
            text-align: left;

            &::before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                background-color: $color-neutral-100;
                border: 1px solid $color-neutral-800;
                border-radius: 3px;
                top: 2px;
                left: 0;
            }
        }

        &:checked + label {
            &::before {
                background-color: $color-brand;
                border-color: $color-brand;
            }

            &::after {
                @extend %ico;
                content: ico-char(check);
                position: absolute;
                color:white;
                font-size: 9px;
                top: 5px;
                left: 3px;
                width: 16px;
                height: 16px;
            }
        }

        &.has-error {
            + label {
                &::before {
                    border-color: $color-red;
                    background-color: #fbe8d7;

                }
            }
        }
    }
}