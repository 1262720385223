.btn {
   appearance: none;
   outline: none;
   box-shadow: none;
   border: 2px solid white;
   border-radius: 7px;
   background-image: none;
   cursor: pointer;
   min-height: rem(46px);
   display: inline-flex;
   justify-content: center;
   align-items: center;
   padding: 0 2rem;
   transition: background-color .2s;
   background-color: $color-button;
   color: #ffffff;
   font-weight: bold;
   text-transform: uppercase;

   @include link {
      color: #ffffff;
   }

   @include link-over() {
      background-color: darken($color-button, 6%);
      text-decoration: none;
   }

}
