/*****************************************
* Blackwell - MIXINS
*****************************************/

@mixin grid($breakpointMap...) {
   display: grid;

   $nextMapIndex: 1;
   $firstBreakpoint: nth(nth($breakpointMap, $nextMapIndex), 1);
   $gap: 0;

   @each $breakpoint in $breakpointMap {
      $nextMapIndex: $nextMapIndex + 1;
      $nextMapValue: if(length($breakpointMap) >= $nextMapIndex, nth(nth($breakpointMap, $nextMapIndex), 1), false);

      $currentBreakpoint: nth($breakpoint, 1);
      $amount: nth($breakpoint, 2);
      $gap: if(length($breakpoint) == 3, nth($breakpoint, 3), $gap);
      $gap: if(length($breakpoint) == 4, nth($breakpoint, 3) nth($breakpoint, 4), $gap);

      @if $nextMapValue != false {
         $clamped: $nextMapValue - to-length(1, unit($nextMapValue));

         @include minmax($currentBreakpoint, $clamped) {
            grid-template-columns: repeat($amount, 1fr);
            //grid-gap: $gap;
            gap: $gap;
         }
      } @else {
         @include min($currentBreakpoint) {
            grid-template-columns: repeat($amount, 1fr);
            //grid-gap: $gap;
            gap: $gap;
         }
      }
   }
}

@mixin scrollable-items($amount, $margin) {
   $total: length(&);
   $selector-map: ();

   @each $s in & {
      $selector: list-pop($s);
      $selector-map: append($selector-map, $selector, comma);
   }

   $compound: implode($selector-map, ", ");

   @at-root {
      #{$compound} {
         overflow-x: scroll;
         -webkit-overflow-scrolling: touch;
         white-space: nowrap;
         font-size: 0;
      }
   }

   display: inline-block;
   vertical-align: top;
   white-space: normal;
   font-size: 1rem;

   $width: null;

   @if unitless($margin) {
      $margin: percentage(strip-unit($margin) / 100);
   }

   @if unit($margin) == unquote("%") {
      $marginTotal: percentage(strip-unit($margin) / 100);
      $width: (100% - ($marginTotal * ($amount - 1))) / $amount;
   } @else {
      $widthItem: 100% / $amount;
      $marginTotal: ($margin * ($amount - 1)) / $amount;
      $width: if($amount > 1, calc(#{$widthItem} - #{$marginTotal}), 100%);
   }

   width: $width;
   margin-left: $margin;
   margin-top: 0;

   &:first-child {
      margin-left: 0;
   }

   @content;
}

@mixin link($selector: null, $visited: true) {
   @if $selector != null {
      $selector: unquote($selector);

      @if $visited == true {
         #{$selector},
         #{$selector}:link,
         #{$selector}:visited {
            @content;
         }
      } @else {
         #{$selector},
         #{$selector}:link {
            @content;
         }
      }
   } @else {
      &,
      &:link,
      &:visited {
         @content;
      }
   }
}

@mixin link-visited($selector: null) {
   @if $selector != null {
      $selector: unquote($selector);

      #{$selector}:visited {
         @content;
      }
   } @else {
      &:visited {
         @content;
      }
   }
}

@mixin link-over($selector: null) {
   @if $selector != null {
      $selector: unquote($selector);

      #{$selector}:hover,
      #{$selector}:focus,
      #{$selector}:active {
         @content;
      }
   } @else {
      &:hover,
      &:focus,
      &:active {
         @content;
      }
   }
}

@mixin link-all($selector: null) {
   @if $selector != null {
      $selector: unquote($selector);

      #{$selector},
      #{$selector}:link,
      #{$selector}:visited,
      #{$selector}:hover,
      #{$selector}:focus,
      #{$selector}:active {
         @content;
      }
   } @else {
      &,
      &:link,
      &:visited,
      &:hover,
      &:focus,
      &:active {
         @content;
      }
   }
}

@mixin font-face($fontFaceMap, $filePath, $fileName) {
   $family: map-get($fontFaceMap, family);
   $weight: if(map-has-key($fontFaceMap, weight), map-get($fontFaceMap, weight), normal);
   $style: if(map-has-key($fontFaceMap, style), map-get($fontFaceMap, style), normal);

   @font-face {
      font: {
         family: "#{$family}";
         weight: $weight;
         style: $style;
         display: swap;
      }

      src: url("#{$filePath}#{$fileName}.woff2") format("woff2"), url("#{$filePath}#{$fileName}.woff") format("woff");
   }
}

@mixin min($value) {
   @media screen and (min-width: $value) {
      @content;
   }
}

@mixin max($value) {
   @media screen and (max-width: $value) {
      @content;
   }
}

@mixin minmax($value-min, $value-max) {
   @media screen and (min-width: $value-min) and (max-width: $value-max) {
      @content;
   }
}

@mixin quantity-query($condition, $selector: &) {
   $conditionComplexity: length($condition);

   $least: nth($condition, 2);
   $selectorMap: ();

   @each $s in & {
      $l: length($s);
      $last: nth($s, $l);

      $s: implode($s, " ");

      @if $conditionComplexity == 2 {
         $cond: nth($condition, 1);

         @if $cond == min {
            $selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least})", space);
            $selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}) ~ #{$last}", space);
         } @else if $cond == max {
            $selectorMap: append($selectorMap, "#{$s}:nth-last-child(-n+#{$least})", space);
            $selectorMap: append($selectorMap, "#{$s}:nth-last-child(-n+#{$least}) ~ #{$last}", space);
         } @else if $cond == exactly {
            $selectorMap: append(
               $selectorMap,
               "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$least}):first-child",
               space
            );
            $selectorMap: append(
               $selectorMap,
               "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$least}):first-child ~ #{$last}",
               space
            );
         }
      } @else {
         $most: nth($condition, 4);
         $selectorMap: append(
            $selectorMap,
            "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$most}):first-child",
            space
         );
         $selectorMap: append(
            $selectorMap,
            "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$most}):first-child ~ #{$last}",
            space
         );
      }
   }

   $compound: implode($selectorMap, ", ");

   @at-root {
      #{$compound} {
         @content;
      }
   }
}

@mixin fill($top: 0, $right: 0, $bottom: 0, $left: 0, $position: absolute) {
   position: $position;
   top: $top;
   right: $right;
   bottom: $bottom;
   left: $left;
}

@mixin pad($color, $side: left, $element: before, $width: 999em) {
   $from: if($side == right, left, right);

   &::#{$element} {
      content: "";
      position: absolute;
      width: $width;
      #{$from}: 100%;
      top: 0;
      bottom: 0;
      background-color: $color;
   }
}

@mixin headings($from, $through) {
   $start: clamp($from, 1, 6);
   $end: clamp($through, 1, 6);
   $selectors: ();

   @for $level from $start through $end {
      $selectors: append($selectors, "h#{$level}", comma);
   }

   $compound: implode($selectors, ", ");

   #{$compound} {
      @content;
   }
}
