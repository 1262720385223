@font-face {
	font-family: "icons";
	src: url('../fonts/icons.woff2') format('woff2'),
	url('../fonts/icons.woff') format('woff');
}

@mixin ico-styles {
	font-family: "icons";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-rendering: auto;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
	line-height: 1;
	// vertical-align: middle;
}

%ico {
	@include ico-styles;
}

@function ico-char($filename) {
	$char: "";

	@if $filename == arrow-down {
	$char: "\E001";
}
	@if $filename == check {
	$char: "\E002";
}
	@if $filename == exclamation-sign-circle {
	$char: "\E003";
}
	@if $filename == facebook {
	$char: "\E004";
}
	@if $filename == linked-in {
	$char: "\E005";
}
	@if $filename == youtube {
	$char: "\E006";
}

@return $char;
}

@mixin ico($filename, $insert: before, $extend: true) {
&:#{$insert} {
	@if $extend {
		@extend %ico;
	} @else {
		@include ico-styles;
	}
	content: ico-char($filename);
}
}

.ico-arrow-down {
	@include ico(arrow-down);
}
.ico-check {
	@include ico(check);
}
.ico-exclamation-sign-circle {
	@include ico(exclamation-sign-circle);
}
.ico-facebook {
	@include ico(facebook);
}
.ico-linked-in {
	@include ico(linked-in);
}
.ico-youtube {
	@include ico(youtube);
}