.footer {
    position: relative;
    margin-top: 100px;
    padding: 3rem 0 1rem;
    background-color: $color-text;
    color: white;

    @include min(800px) {
        margin-top: 11vw;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 100%;
        height: 100px;
        width: 100%;
        left: 0;
        background: url('#{$images-path}/footer.png') repeat-x center bottom;
        background-size: 800px auto;

        @include min(800px) {
            height: 180px;
            background-size: 100% auto
        }

        @include min(1920px) {
            background-size: 1920px auto;
        }
    }

    &__content {
        @include min(450px) {
            display: flex;
            flex-wrap: wrap;
        }

        &__box {
            margin-bottom: 1.5rem;
            line-height: 1.55;

            @include minmax(450px, 850px) {
                &--logo, &--copyright {
                    flex-basis: 100%;
                }
            }

            @include min(450px) {
                flex: 0 1 50%;
            }

            @include min(850px) {
                flex: 0 1 25%;
            }
        }
    }

    &__address {
        font-style: normal;
    }

    &__logo {
        width: 120px;
        margin-right: 5px;
    }

    h3 {
        font-size: 1rem;
        margin: 7px 0 10px;
        padding: 0;
    }

    &__contact-icons {
        li {
            margin: 0 10px 10px 0;
            display: inline-block;

            a {
                display: inline-block;
                width: 34px;
                height: 34px;
                background-color: $color-neutral-700;
                border-radius: 50%;
                line-height: 30px;
                text-align: center;
                vertical-align: middle;
                transition: background-color .2s;

                &:hover {
                    background-color: $color-neutral-800;
                }

                i {
                    vertical-align: middle;
                    color: $color-neutral-300;
                    line-height: 34px;
                }
            }
        }
    }

    &__copyright {
        align-self: flex-end;
    }

    &__copyright {

        @include min(850px) {
            align-self: flex-end;
            text-align: right;
        }
    }
}