input.form-control {
   display: block;
   width: 100%;
   border: 1px solid $color-neutral-800;
   padding: 4px 15px;
   color: $color-text;
   font-weight: normal;
   background-color: $color-neutral-100;
   background-image: none;
   height: rem(46px);
   border-radius: 7px;
   line-height: rem(24px);
   font-size: rem(16px);
   box-shadow: none;
   outline: none;
   appearance: none;
   margin-bottom: 1rem;

   &:disabled {
      background-color: #e9ecef;
   }

   &.has-error {
      border-color: $color-red;
      background-color: #fbe8d7;
   }
}

.input-group {
   position: relative;

   .input-group-prepend,
   .input-group-append {
      position: absolute;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      color: $color-neutral-600;
   }

   .input-group-prepend {
      padding: 0 0 0 15px;
      left: 0;

      & + input {
         padding-left: rem(50px);
      }
   }

   .input-group-append {
      padding: 0 15px 0 0;
      right: 0;
   }

   input.with-append {
      padding-right: rem(50px);
   }
}
