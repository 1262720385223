.what-you-get {
    background-color: $color-neutral-100;
    padding: 3rem 0;

    &__list-items {
        padding-top: 0.5rem;
    }

    &__content {
        @include min(800px) {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
        }

        &__info {
            @include min(800px) {
                flex: 0 1 48%;
            }

            @include min($max-width) {
                padding-right: 2%;
            }
        }

        &__app-screenshot {
            text-align: center;
            margin-top: 2rem;

            @include min(800px) {
                margin: 0;
                flex-grow: 1;
            }

            img {
                max-width: 65%;
                width: 350px;

                @include min(800px) {
                    max-width: 75%;
                    width: auto;
                }
            }
        }
    }
}