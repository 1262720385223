.alert {
    position: relative;
    padding: 0.75rem 0;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    //border-width: 1px 0;
    //border-style: solid;
    //border-color: transparent;
    //box-shadow: 0 2px 3px rgba(0, 0, 0, 0.10);
    z-index: 11;
    text-align: center;

    &-success {
        color: white;
        background-color: $color-green;
        //border-color: $color-green;
    }

    //i {
    //    margin-right: 0.5rem;
    //}

    //&.alert-dismissible {
    //    padding-right: 3rem;
    //
    //    .close {
    //        position: absolute;
    //        top: -0.75rem;
    //        font-weight: bold;
    //        font-size: 1.5rem;
    //        right: 0;
    //        padding: 0.5rem 1.25rem;
    //        color: inherit;
    //        cursor: pointer;
    //
    //        &:hover{
    //            color: $color-brand;
    //        }
    //    }
    //}
}