@charset "UTF-8";
/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	IMAGES PATH
==============================*/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
._fsr:not(:focus):not(:active) {
  position: absolute !important;
  word-wrap: normal !important;
  white-space: nowrap;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

._nls {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

.flex {
  display: flex;
}

.color-brand {
  color: #eb8025;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.bold {
  font-weight: bold;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.hidden {
  display: none !important;
}

.footer {
  position: relative;
  margin-top: 100px;
  padding: 3rem 0 1rem;
  background-color: #424243;
  color: white;
}

@media screen and (min-width: 800px) {
  .footer {
    margin-top: 11vw;
  }
}

.footer::before {
  content: "";
  position: absolute;
  bottom: 100%;
  height: 100px;
  width: 100%;
  left: 0;
  background: url("../images/footer.png") repeat-x center bottom;
  background-size: 800px auto;
}

@media screen and (min-width: 800px) {
  .footer::before {
    height: 180px;
    background-size: 100% auto;
  }
}

@media screen and (min-width: 1920px) {
  .footer::before {
    background-size: 1920px auto;
  }
}

@media screen and (min-width: 450px) {
  .footer__content {
    display: flex;
    flex-wrap: wrap;
  }
}

.footer__content__box {
  margin-bottom: 1.5rem;
  line-height: 1.55;
}

@media screen and (min-width: 450px) and (max-width: 850px) {
  .footer__content__box--logo, .footer__content__box--copyright {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 450px) {
  .footer__content__box {
    flex: 0 1 50%;
  }
}

@media screen and (min-width: 850px) {
  .footer__content__box {
    flex: 0 1 25%;
  }
}

.footer__address {
  font-style: normal;
}

.footer__logo {
  width: 120px;
  margin-right: 5px;
}

.footer h3 {
  font-size: 1rem;
  margin: 7px 0 10px;
  padding: 0;
}

.footer__contact-icons li {
  margin: 0 10px 10px 0;
  display: inline-block;
}

.footer__contact-icons li a {
  display: inline-block;
  width: 34px;
  height: 34px;
  background-color: #7c7c7c;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  transition: background-color .2s;
}

.footer__contact-icons li a:hover {
  background-color: #545454;
}

.footer__contact-icons li a i {
  vertical-align: middle;
  color: #e2e2e2;
  line-height: 34px;
}

.footer__copyright {
  align-self: flex-end;
}

@media screen and (min-width: 850px) {
  .footer__copyright {
    align-self: flex-end;
    text-align: right;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1190px;
  width: 100%;
  padding: 0 5%;
  position: relative;
}

@media screen and (min-width: 600px) {
  .container {
    padding: 0 22px;
  }
}

@media screen and (min-width: 1190px) {
  ul.fish-indent {
    font-size: 1.125rem;
  }
}

ul.fish-indent li {
  margin-bottom: 1.2rem;
  position: relative;
  padding-left: 2.2rem;
}

ul.fish-indent li::before {
  content: "";
  position: absolute;
  left: 0;
  background: url("../images/fish.svg") no-repeat center center;
  background-size: contain;
  height: 1.5rem;
  width: 1.5rem;
}

@font-face {
  font-family: "icons";
  src: url("../fonts/icons.woff2") format("woff2"), url("../fonts/icons.woff") format("woff");
}

.ico-arrow-down:before, .ico-check:before, .ico-exclamation-sign-circle:before, .ico-facebook:before, .ico-linked-in:before, .ico-youtube:before, .checkbox input[type="checkbox"]:checked + label::after, .header__buttons__more::before {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
  text-decoration: none;
  text-transform: none;
  line-height: 1;
}

.ico-arrow-down:before {
  content: "";
}

.ico-check:before {
  content: "";
}

.ico-exclamation-sign-circle:before {
  content: "";
}

.ico-facebook:before {
  content: "";
}

.ico-linked-in:before {
  content: "";
}

.ico-youtube:before {
  content: "";
}

.alert {
  position: relative;
  padding: 0.75rem 0;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  z-index: 11;
  text-align: center;
}

.alert-success {
  color: white;
  background-color: #91c84e;
}

label {
  color: #424243;
  font-size: 0.9375rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.6em 0;
}

label .required {
  margin-left: 0.25em;
}

.btn {
  appearance: none;
  outline: none;
  box-shadow: none;
  border: 2px solid white;
  border-radius: 7px;
  background-image: none;
  cursor: pointer;
  min-height: 2.875rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  transition: background-color .2s;
  background-color: #eb8025;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
}

.btn, .btn:link, .btn:visited {
  color: #ffffff;
}

.btn:hover, .btn:focus, .btn:active {
  background-color: #dd7114;
  text-decoration: none;
}

input.form-control {
  display: block;
  width: 100%;
  border: 1px solid #545454;
  padding: 4px 15px;
  color: #424243;
  font-weight: normal;
  background-color: #f8f8f8;
  background-image: none;
  height: 2.875rem;
  border-radius: 7px;
  line-height: 1.5rem;
  font-size: 1rem;
  box-shadow: none;
  outline: none;
  appearance: none;
  margin-bottom: 1rem;
}

input.form-control:disabled {
  background-color: #e9ecef;
}

input.form-control.has-error {
  border-color: #e61313;
  background-color: #fbe8d7;
}

.input-group {
  position: relative;
}

.input-group .input-group-prepend,
.input-group .input-group-append {
  position: absolute;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  color: #969696;
}

.input-group .input-group-prepend {
  padding: 0 0 0 15px;
  left: 0;
}

.input-group .input-group-prepend + input {
  padding-left: 3.125rem;
}

.input-group .input-group-append {
  padding: 0 15px 0 0;
  right: 0;
}

.input-group input.with-append {
  padding-right: 3.125rem;
}

.checkbox {
  position: relative;
  margin-bottom: 0.5rem;
}

.checkbox input[type="checkbox"] {
  position: absolute;
  margin: 3px 0 0 3px;
  left: 0;
  opacity: 0;
}

.checkbox input[type="checkbox"] + label {
  margin-bottom: 0.5rem;
  padding: 0;
  font-size: 13px;
  text-transform: none;
  font-weight: 400;
  line-height: normal;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  color: #9f9f9f;
  text-align: left;
}

.checkbox input[type="checkbox"] + label::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  background-color: #f8f8f8;
  border: 1px solid #545454;
  border-radius: 3px;
  top: 2px;
  left: 0;
}

.checkbox input[type="checkbox"]:checked + label::before {
  background-color: #eb8025;
  border-color: #eb8025;
}

.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  color: white;
  font-size: 9px;
  top: 5px;
  left: 3px;
  width: 16px;
  height: 16px;
}

.checkbox input[type="checkbox"].has-error + label::before {
  border-color: #e61313;
  background-color: #fbe8d7;
}

form .help-block {
  font-size: 0.875rem;
  margin-bottom: 0.75em;
  display: block;
  text-align: left;
}

form .help-block:first-of-type {
  margin-top: -0.5em;
}

form .help-block [class|="ico"] {
  margin-right: 0.5em;
}

form .help-block.text-danger {
  color: #dd4b39;
}

.cookies-information {
  background-color: #424243;
  font-size: 0.9375rem;
  color: white;
  padding: 15px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 99;
}

@media screen and (min-width: 900px) {
  .cookies-information {
    padding: 20px 0;
    text-align: left;
  }
}

.cookies-information a {
  color: #eb8025;
}

.cookies-information .btn {
  font-size: 15px;
  width: 150px;
  margin: 5px 15px 0;
  border: none;
  min-height: 37px;
}

@media screen and (min-width: 900px) {
  .cookies-information .btn {
    float: right;
    margin: -8px 0 -8px 15px;
  }
}

.header {
  background-color: #C9E5E8;
  background-image: url("../images/foreground.svg"), url("../images/clouds.svg"), url("../images/clouds.svg"), linear-gradient(0deg, #c9e5e8 0%, #FAFDFD 100%);
  background-repeat: repeat-x, no-repeat, no-repeat, repeat;
  background-position: 50% 100%, left -100px bottom 10vh, right 0 bottom 15vh, 100% 100%;
  background-size: auto;
}

@media screen and (min-width: 800px) {
  .header {
    background-size: 55% auto, 50% auto, 50% auto, auto;
    min-height: 80vh;
  }
}

@media screen and (min-width: 1920px) {
  .header {
    background-size: 1000px auto, 50% auto, 50% auto, auto;
  }
}

.header__logo {
  width: 150px;
  margin-top: 30px;
  margin-bottom: 35px;
}

@media screen and (min-width: 450px) {
  .header__logo {
    width: 180px;
  }
}

@media screen and (min-width: 800px) {
  .header__logo {
    margin-bottom: 8vh;
    margin-top: 7vh;
    width: 250px;
    margin-left: 5%;
  }
}

@media screen and (min-width: 1190px) {
  .header__logo {
    margin-left: 10%;
  }
}

.header__content {
  padding-bottom: 100px;
}

@media screen and (min-width: 800px) {
  .header__content {
    padding-bottom: 140px;
    padding-left: 5%;
  }
}

@media screen and (min-width: 1190px) {
  .header__content {
    padding-left: 10%;
  }
}

.header__title {
  font-size: 1.875rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 450px) {
  .header__title {
    font-size: 2.1875rem;
  }
}

@media screen and (min-width: 800px) {
  .header__title {
    font-size: 2.625rem;
  }
}

.header__motto {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6vh;
}

.header__motto li {
  position: relative;
  font-weight: 700;
  font-size: 0.875rem;
}

@media screen and (min-width: 450px) {
  .header__motto li {
    font-size: 0.9375rem;
  }
}

@media screen and (min-width: 800px) {
  .header__motto li {
    font-size: 1.125rem;
  }
}

.header__motto li:not(:first-child)::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  vertical-align: middle;
  background-color: black;
  border-radius: 50%;
  margin: 0 7px;
}

.header__benefits {
  margin-left: 5%;
  margin-bottom: 6vh;
}

@media screen and (min-width: 500px) {
  .header__benefits {
    max-width: 50%;
  }
}

@media screen and (min-width: 800px) {
  .header__benefits {
    max-width: 45%;
  }
}

.header__buttons {
  margin-left: calc(5% + 2.2rem);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.header__buttons__interest {
  margin-bottom: 0.5rem;
}

.header__buttons__more {
  position: relative;
  line-height: 2em;
}

.header__buttons__more::before {
  content: "";
  font-size: 0.8em;
  padding-right: 8px;
}

.header__buttons__more:hover::before {
  animation: arrowBounce 0.3s ease-in-out infinite alternate;
}

@keyframes arrowBounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(3px);
  }
}

.header__hand {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 125px;
  max-height: 650px;
}

@media screen and (min-width: 450px) {
  .header__hand {
    max-width: 40%;
  }
}

@media screen and (min-width: 1190px) {
  .header__hand {
    right: 2%;
    max-height: none;
  }
}

.benefits {
  padding: 2rem 0 1rem;
}

@media screen and (min-width: 450px) {
  .benefits {
    padding: 2rem 0 0.5rem;
  }
}

@media screen and (min-width: 450px) {
  .benefits__list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

.benefits__item {
  text-align: center;
  margin-bottom: 1rem;
  flex-basis: 33%;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

@media screen and (min-width: 450px) {
  .benefits__item {
    margin-bottom: 2rem;
    flex-direction: column;
  }
}

@media screen and (min-width: 800px) {
  .benefits__item {
    flex-basis: 20%;
  }
}

.benefits__item img {
  height: 2.5em;
  font-size: 1em;
  margin-right: 2rem;
}

@media screen and (min-width: 450px) {
  .benefits__item img {
    margin-right: 0;
    height: 3em;
  }
}

@media screen and (min-width: 800px) {
  .benefits__item img {
    height: 3.5em;
  }
}

@media screen and (min-width: 450px) {
  .benefits__item__text {
    margin-top: 0.5rem;
    width: 135px;
  }
}

@media screen and (min-width: 800px) {
  .benefits__item__text {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 1000px) {
  .benefits__item__text {
    width: 160px;
  }
}

.what-you-get {
  background-color: #f8f8f8;
  padding: 3rem 0;
}

.what-you-get__list-items {
  padding-top: 0.5rem;
}

@media screen and (min-width: 800px) {
  .what-you-get__content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

@media screen and (min-width: 800px) {
  .what-you-get__content__info {
    flex: 0 1 48%;
  }
}

@media screen and (min-width: 1190px) {
  .what-you-get__content__info {
    padding-right: 2%;
  }
}

.what-you-get__content__app-screenshot {
  text-align: center;
  margin-top: 2rem;
}

@media screen and (min-width: 800px) {
  .what-you-get__content__app-screenshot {
    margin: 0;
    flex-grow: 1;
  }
}

.what-you-get__content__app-screenshot img {
  max-width: 65%;
  width: 350px;
}

@media screen and (min-width: 800px) {
  .what-you-get__content__app-screenshot img {
    max-width: 75%;
    width: auto;
  }
}

.extra-tips {
  background-color: #C9E5E8;
  padding: 3rem 0;
}

.extra-tips__list-tips {
  padding-top: 0.5rem;
}

@media screen and (min-width: 800px) {
  .extra-tips__list-tips {
    width: 49%;
  }
}

.extra-tips__photo {
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
  width: 500px;
  max-width: 80%;
  display: block;
  margin: 2rem auto 0;
}

@media screen and (min-width: 800px) {
  .extra-tips__photo {
    max-width: 47%;
    position: absolute;
    right: 0;
    bottom: -6.5rem;
    width: auto;
  }
}

.contact-us {
  padding: 4rem 0 3rem;
}

@media screen and (min-width: 650px) {
  .contact-us {
    padding-top: 8rem;
  }
}

.contact-us__content {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

.contact-us__title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.contact-us__subtitle {
  margin: 0 0 3rem 0;
}

@media screen and (min-width: 650px) {
  .contact-us__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1rem;
  }
}

.contact-us__conditions {
  margin-bottom: 2rem;
}

.contact-us__conditions + .alert {
  margin-top: -1.5rem;
}
