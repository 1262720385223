._fsr:not(:focus):not(:active) {
    position: absolute !important;
    word-wrap: normal !important;
    white-space: nowrap;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
}

._nls {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
}

.flex {
    display: flex;
}

.color-brand {
    color: $color-brand;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.flex-grow {
    flex-grow: 1;
}

.flex-wrap {
    flex-wrap: wrap;
}

.bold {
    font-weight: bold;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.hidden {
    display: none !important;
}
