label {
   color: $color-text;
   font-size: rem(15px);
   text-transform: uppercase;
   display: inline-block;
   padding: 0.6em 0;

   .required {
      margin-left: 0.25em;
   }
}